var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "80%",
            visible: _vm.dialogInvoicePayment,
            "close-on-click-modal": false,
          },
          on: {
            open: _vm.handleInvoicePaymentDialogOpen,
            close: _vm.handleInvoicePaymentDialogClose,
          },
        },
        [
          _c("div", { staticClass: "header-section" }, [
            _c("h3", { staticClass: "second-color-font" }, [
              _vm._v(_vm._s(_vm.dialogTitle)),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { round: "", outline: "" },
                    on: { click: _vm.handleAddPayment },
                  },
                  [_vm._v(_vm._s(_vm.addButtonText))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "paymentForm" },
            [
              _c(
                "el-form",
                {
                  ref: "paymentForm",
                  attrs: {
                    model: _vm.paymentForm,
                    rules: _vm.paymentForm.rules,
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "el-table-payment-form",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.paymentForm.tableData,
                        border: "",
                        "header-cell-style": { background: "#F4F6FA" },
                      },
                    },
                    [
                      _vm.isSellerView
                        ? _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              width: "240",
                              prop: "receiptDate",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c("span", [_vm._v("* ")]),
                                      _vm._v("Date"),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: `tableData.${scope.$index}.receiptDate`,
                                            rules:
                                              _vm.paymentForm.rules.receiptDate,
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: { type: "datetime" },
                                            model: {
                                              value: scope.row.receiptDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "receiptDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.receiptDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3939977000
                            ),
                          })
                        : _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              width: "240",
                              prop: "paymentDate",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("* ")]),
                                    _vm._v("Date"),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: `tableData.${scope.$index}.paymentDate`,
                                          rules:
                                            _vm.paymentForm.rules.paymentDate,
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            width: "100px",
                                            type: "datetime",
                                          },
                                          model: {
                                            value: scope.row.paymentDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "paymentDate",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.paymentDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                      _vm.isSellerView
                        ? _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              width: "200",
                              prop: "amountReceived",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c("span", [_vm._v("* ")]),
                                      _vm._v("Amount Receipt"),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: `tableData.${scope.$index}.amountReceived`,
                                            rules:
                                              _vm.paymentForm.rules
                                                .amountReceived,
                                          },
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              precision: 3,
                                              step: 0.1,
                                              controls: false,
                                            },
                                            model: {
                                              value: scope.row.amountReceived,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "amountReceived",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.amountReceived",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              950946888
                            ),
                          })
                        : _c("el-table-column", {
                            attrs: {
                              fixed: "",
                              width: "200",
                              prop: "amountPaid",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("span", [_vm._v("* ")]),
                                    _vm._v("Amount Payment"),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: `tableData.${scope.$index}.amountPaid`,
                                          rules:
                                            _vm.paymentForm.rules.amountPaid,
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            precision: 3,
                                            step: 0.1,
                                            controls: false,
                                          },
                                          model: {
                                            value: scope.row.amountPaid,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "amountPaid",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.amountPaid",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                      _c("el-table-column", {
                        attrs: { prop: "bankAccount" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("span", [_vm._v("* ")]),
                                _vm._v("Bank Account"),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: `tableData.${scope.$index}.bankAccount`,
                                      rules: _vm.paymentForm.rules.bankAccount,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.bankAccount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "bankAccount",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.bankAccount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "transactionId" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("span", [_vm._v("* ")]),
                                _vm._v("Transaction ID"),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: `tableData.${scope.$index}.transactionId`,
                                      rules:
                                        _vm.paymentForm.rules.transactionId,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.transactionId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "transactionId",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.transactionId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "remark", label: "Remark" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: `tableData.${scope.$index}.remark`,
                                      rules: _vm.paymentForm.rules.remark,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea", rows: 1 },
                                      model: {
                                        value: scope.row.remark,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "remark", $$v)
                                        },
                                        expression: "scope.row.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Action", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemovePayment(
                                        scope.$index
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "note-section", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24, lg: 12 } },
                [
                  _c("h3", { staticClass: "second-color-font" }, [
                    _vm._v("Credit Note"),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticClass: "el-table-payment-form",
                      attrs: {
                        data: _vm.creditNoteTableData,
                        border: "",
                        "header-cell-style": { background: "#F4F6FA" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "systemGeneratedNoteNo",
                          label: "Credit Note No.",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.previewFile(
                                          scope.row.noteFileId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row
                                          ? scope.row.systemGeneratedNoteNo
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "noteDate", label: "Date Created" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("i", { staticClass: "el-icon-time" }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(
                                        new Date(scope.row.noteDate)
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "noteTotal", label: "Amount" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.noteTotal
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          `${scope.row.noteTotal.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}`
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 24, lg: 12 } },
                [
                  _c("h3", { staticClass: "second-color-font" }, [
                    _vm._v("Debit Note"),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticClass: "el-table-payment-form",
                      attrs: {
                        data: _vm.debitNoteTableData,
                        border: "",
                        "header-cell-style": { background: "#F4F6FA" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "systemGeneratedNoteNo",
                          label: "Debit Note No.",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.previewFile(
                                          scope.row.noteFileId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row
                                          ? scope.row.systemGeneratedNoteNo
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "noteDate", label: "Date Created" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("i", { staticClass: "el-icon-time" }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(
                                        new Date(scope.row.noteDate)
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "noteTotal", label: "Amount" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.noteTotal
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          `${scope.row.noteTotal.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}`
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", { staticClass: "divider" }),
          _c(
            "div",
            { staticClass: "summary-section" },
            [
              _c("h3", { staticClass: "second-color-font" }, [
                _vm._v("Invoice Summary"),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "summary-section-left",
                      attrs: { sm: 24, md: 12 },
                    },
                    [
                      _c("div", [
                        _c("p", [_vm._v("Invoice Total")]),
                        _c("hr"),
                        _c("p", { staticClass: "bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.invoicePaymentSettlement &&
                                _vm.invoicePaymentSettlement.invoiceTotal
                                ? `${
                                    _vm.invoicePaymentSettlement.currency
                                  } ${_vm.invoicePaymentSettlement.invoiceTotal.toLocaleString(
                                    "en",
                                    { minimumFractionDigits: 2 }
                                  )}`
                                : ""
                            )
                          ),
                        ]),
                      ]),
                      _c("div", [
                        _c("p", [_vm._v("Credit Note Total")]),
                        _c("hr"),
                        _vm.invoicePaymentSettlement &&
                        _vm.invoicePaymentSettlement.totalCreditNoteAmount
                          ? _c("p", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.invoicePaymentSettlement
                                    ? `${
                                        _vm.invoicePaymentSettlement.currency
                                      } ${_vm.invoicePaymentSettlement.totalCreditNoteAmount.toLocaleString(
                                        "en",
                                        { minimumFractionDigits: 2 }
                                      )}`
                                    : ""
                                )
                              ),
                            ])
                          : _vm.invoicePaymentSettlement
                          ? _c("p", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  `${_vm.invoicePaymentSettlement.currency} 0.00`
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", [
                        _c("p", [_vm._v("Debit Note Total")]),
                        _c("hr"),
                        _vm.invoicePaymentSettlement &&
                        _vm.invoicePaymentSettlement.totalDebitNoteAmount
                          ? _c("p", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.invoicePaymentSettlement
                                    ? `${
                                        _vm.invoicePaymentSettlement.currency
                                      } ${_vm.invoicePaymentSettlement.totalDebitNoteAmount.toLocaleString(
                                        "en",
                                        { minimumFractionDigits: 2 }
                                      )}`
                                    : ""
                                )
                              ),
                            ])
                          : _vm.invoicePaymentSettlement
                          ? _c("p", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  `${_vm.invoicePaymentSettlement.currency} 0.00`
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", [
                        _c("p", [_vm._v("Proforma Invoice Total")]),
                        _c("hr"),
                        _vm.invoicePaymentSettlement &&
                        _vm.invoicePaymentSettlement.proformaInvoiceAmount
                          ? _c("p", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.invoicePaymentSettlement
                                    ? `${
                                        _vm.invoicePaymentSettlement.currency
                                      } ${_vm.invoicePaymentSettlement.proformaInvoiceAmount.toLocaleString(
                                        "en",
                                        { minimumFractionDigits: 2 }
                                      )}`
                                    : ""
                                )
                              ),
                            ])
                          : _vm.invoicePaymentSettlement
                          ? _c("p", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  `${_vm.invoicePaymentSettlement.currency} 0.00`
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "summary-section-right",
                      attrs: { sm: 24, md: 12 },
                    },
                    [
                      _vm.isSellerView
                        ? _c("div", [
                            _c("p", [_vm._v("Total Amount Receivable")]),
                            _vm.invoicePaymentSettlement
                              ? _c("h2", { staticClass: "bold" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.invoicePaymentSettlement.currency ||
                                        ""
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.invoicePaymentSettlement
                                          .totalAmountReceivable
                                          ? `${_vm.invoicePaymentSettlement.totalAmountReceivable.toLocaleString(
                                              "en",
                                              { minimumFractionDigits: 2 }
                                            )}`
                                          : "0.00"
                                      )
                                  ),
                                ])
                              : _c("h2", { staticClass: "bold" }, [
                                  _vm._v("0.00"),
                                ]),
                          ])
                        : _c("div", [
                            _c("p", [_vm._v("Total Amount Payable")]),
                            _vm.invoicePaymentSettlement
                              ? _c("h2", { staticClass: "bold" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.invoicePaymentSettlement.currency ||
                                        ""
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.invoicePaymentSettlement
                                          .totalAmountPayable
                                          ? `${_vm.invoicePaymentSettlement.totalAmountPayable.toLocaleString(
                                              "en",
                                              { minimumFractionDigits: 2 }
                                            )}`
                                          : "0.00"
                                      )
                                  ),
                                ])
                              : _c("h2", { staticClass: "bold" }, [
                                  _vm._v("0.00"),
                                ]),
                          ]),
                      _c("div", [
                        _c("p", [_vm._v("Outstanding Amount")]),
                        _vm.isSellerView && _vm.invoicePaymentSettlement
                          ? _c("h2", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.invoicePaymentSettlement.currency || ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.invoicePaymentSettlement
                                      .receivableOutstandingAmount
                                      ? `${_vm.invoicePaymentSettlement.receivableOutstandingAmount.toLocaleString(
                                          "en",
                                          { minimumFractionDigits: 2 }
                                        )}`
                                      : "0.00"
                                  )
                              ),
                            ])
                          : !_vm.isSellerView && _vm.invoicePaymentSettlement
                          ? _c("h2", { staticClass: "bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.invoicePaymentSettlement.currency || ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.invoicePaymentSettlement
                                      .payableOutstandingAmount
                                      ? `${_vm.invoicePaymentSettlement.payableOutstandingAmount.toLocaleString(
                                          "en",
                                          { minimumFractionDigits: 2 }
                                        )}`
                                      : "0.00"
                                  )
                              ),
                            ])
                          : _c("h2", { staticClass: "bold" }, [_vm._v("0.00")]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-section" },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", outline: "" },
                  on: { click: _vm.handleInvoicePaymentDialogClose },
                },
                [_vm._v("Close")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "primary-button",
                  on: { click: _vm.handleSavePayment },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }