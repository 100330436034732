<template>
    <div>
        <el-dialog
          width="80%"
          :visible="dialogInvoicePayment"
          :close-on-click-modal="false"
          @open="handleInvoicePaymentDialogOpen"
          @close="handleInvoicePaymentDialogClose"
        >
            <div class="header-section">
                <h3 class="second-color-font">{{dialogTitle}}</h3>
                <div>
                    <el-button round outline @click="handleAddPayment">{{addButtonText}}</el-button>
                </div>
            </div>
            <div class="paymentForm">
                <el-form :model="paymentForm" :rules="paymentForm.rules" ref="paymentForm">
                    <el-table class="el-table-payment-form"
                     style="width: 100%" :data="paymentForm.tableData" border :header-cell-style="{ background: '#F4F6FA' }">
                        <el-table-column fixed width="240" v-if="isSellerView" prop="receiptDate">
                            <template v-slot:header><span>* </span>Date</template>
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.receiptDate`" :rules="paymentForm.rules.receiptDate">
                                    <el-date-picker   v-model="scope.row.receiptDate" type="datetime"></el-date-picker>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column fixed width="240" v-else prop="paymentDate">
                            <template v-slot:header><span>* </span>Date</template>
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.paymentDate`" :rules="paymentForm.rules.paymentDate">
                                    <el-date-picker  width="100px"   v-model="scope.row.paymentDate" type="datetime"></el-date-picker>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column fixed width="200"  v-if="isSellerView" prop="amountReceived">
                            <template v-slot:header><span>* </span>Amount Receipt</template>
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.amountReceived`" :rules="paymentForm.rules.amountReceived">
                                    <el-input-number v-model="scope.row.amountReceived" :precision="3" :step="0.1" :controls="false"></el-input-number>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column fixed width="200"  v-else prop="amountPaid">
                            <template v-slot:header><span>* </span>Amount Payment</template>
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.amountPaid`" :rules="paymentForm.rules.amountPaid">
                                  <el-input-number v-model="scope.row.amountPaid" :precision="3" :step="0.1" :controls="false"></el-input-number>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bankAccount">
                            <template v-slot:header><span>* </span>Bank Account</template>
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.bankAccount`" :rules="paymentForm.rules.bankAccount">
                                    <el-input v-model="scope.row.bankAccount"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="transactionId">
                            <template v-slot:header><span>* </span>Transaction ID</template>
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.transactionId`" :rules="paymentForm.rules.transactionId">
                                    <el-input v-model="scope.row.transactionId"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="Remark">
                            <template v-slot="scope">
                                <el-form-item :prop="`tableData.${scope.$index}.remark`" :rules="paymentForm.rules.remark">
                                    <el-input type="textarea"  :rows="1" v-model="scope.row.remark"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="Action" width="80">
                            <template v-slot="scope">
                                <el-button type="danger" icon="el-icon-delete"  circle @click="handleRemovePayment(scope.$index)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
            </div>
            <el-row :gutter="20" class="note-section">
                <el-col  :md="24" :lg="12" >
                    <h3 class="second-color-font">Credit Note</h3>
                    <el-table class="el-table-payment-form" :data="creditNoteTableData" border :header-cell-style="{ background: '#F4F6FA' }">
                        <el-table-column prop="systemGeneratedNoteNo" label="Credit Note No.">
                            <template v-slot="scope">
                                <a href="" @click.prevent="previewFile(scope.row.noteFileId)">{{ scope.row ? scope.row.systemGeneratedNoteNo : '' }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="noteDate" label="Date Created">
                            <template v-slot="scope">
                                <i class="el-icon-time"></i>
                                <span>{{ formatDateTime(new Date(scope.row.noteDate)) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="noteTotal" label="Amount">
                            <template v-slot="scope">
                                <p v-if="scope.row.noteTotal">{{ `${ scope.row.noteTotal.toLocaleString(undefined, {minimumFractionDigits:2})}` }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :md="24" :lg="12" >
                    <h3 class="second-color-font">Debit Note</h3>
                    <el-table class="el-table-payment-form" :data="debitNoteTableData" border :header-cell-style="{ background: '#F4F6FA' }">
                        <el-table-column prop="systemGeneratedNoteNo" label="Debit Note No.">
                            <template v-slot="scope">
                                <a href="" @click.prevent="previewFile(scope.row.noteFileId)">{{ scope.row ? scope.row.systemGeneratedNoteNo : '' }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="noteDate" label="Date Created">
                            <template v-slot="scope">
                                <i class="el-icon-time"></i>
                                <span>{{ formatDateTime(new Date(scope.row.noteDate)) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="noteTotal" label="Amount">
                            <template v-slot="scope">
                                <p v-if="scope.row.noteTotal">{{ `${scope.row.noteTotal.toLocaleString(undefined, {minimumFractionDigits:2})}` }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <el-divider class="divider"></el-divider>
            <div  class="summary-section">
                <h3 class="second-color-font">Invoice Summary</h3>
                <el-row :gutter="10">
                    <el-col  :sm="24" :md="12" class="summary-section-left">
                        <div>
                            <p>Invoice Total</p>
                            <hr>
                            <p class="bold">{{invoicePaymentSettlement && invoicePaymentSettlement.invoiceTotal ? `${invoicePaymentSettlement.currency} ${invoicePaymentSettlement.invoiceTotal.toLocaleString('en', {minimumFractionDigits:2})}` : ''}}</p>
                        </div>
                        <div>
                            <p>Credit Note Total</p>
                            <hr>
                            <p v-if="invoicePaymentSettlement && invoicePaymentSettlement.totalCreditNoteAmount" class="bold">{{invoicePaymentSettlement ? `${invoicePaymentSettlement.currency} ${invoicePaymentSettlement.totalCreditNoteAmount.toLocaleString('en', {minimumFractionDigits:2})}` : ''}}</p>
                            <p v-else-if="invoicePaymentSettlement" class="bold">{{`${invoicePaymentSettlement.currency} 0.00`}}</p>
                        </div>
                        <div>
                            <p>Debit Note Total</p>
                            <hr>
                              <p v-if="invoicePaymentSettlement && invoicePaymentSettlement.totalDebitNoteAmount" class="bold">{{invoicePaymentSettlement ? `${invoicePaymentSettlement.currency} ${invoicePaymentSettlement.totalDebitNoteAmount.toLocaleString('en', {minimumFractionDigits:2})}` : ''}}</p>
                            <p v-else-if="invoicePaymentSettlement" class="bold">{{`${invoicePaymentSettlement.currency} 0.00`}}</p>
                        </div>
                        <div>
                            <p>Proforma Invoice Total</p>
                            <hr>
                            <p v-if="invoicePaymentSettlement && invoicePaymentSettlement.proformaInvoiceAmount" class="bold">{{invoicePaymentSettlement ? `${invoicePaymentSettlement.currency} ${invoicePaymentSettlement.proformaInvoiceAmount.toLocaleString('en', {minimumFractionDigits:2})}` : ''}}</p>
                            <p v-else-if="invoicePaymentSettlement" class="bold">{{`${invoicePaymentSettlement.currency} 0.00`}}</p>
                        </div>
                    </el-col>
                    <el-col :sm="24" :md="12" class="summary-section-right">
                        <div v-if="isSellerView">
                            <p>Total Amount Receivable</p>
                            <h2 class="bold"  v-if="invoicePaymentSettlement">{{invoicePaymentSettlement.currency || ''}} {{invoicePaymentSettlement.totalAmountReceivable ? `${invoicePaymentSettlement.totalAmountReceivable.toLocaleString('en', {minimumFractionDigits:2})}` : '0.00'}}</h2>
                            <h2 v-else class="bold">0.00</h2>
                        </div>
                        <div v-else>
                            <p>Total Amount Payable</p>
                            <h2 class="bold" v-if="invoicePaymentSettlement">{{invoicePaymentSettlement.currency || ''}} {{invoicePaymentSettlement.totalAmountPayable ? `${invoicePaymentSettlement.totalAmountPayable.toLocaleString('en', {minimumFractionDigits:2})}` : '0.00'}}</h2>
                            <h2 v-else class="bold">0.00</h2>
                        </div>
                        <div>
                            <p>Outstanding Amount</p>
                            <h2 v-if="isSellerView && invoicePaymentSettlement" class="bold">{{invoicePaymentSettlement.currency || ''}} {{invoicePaymentSettlement.receivableOutstandingAmount ? `${invoicePaymentSettlement.receivableOutstandingAmount.toLocaleString('en', {minimumFractionDigits:2})}` : '0.00'}}</h2>
                            <h2 v-else-if="!isSellerView && invoicePaymentSettlement" class="bold">{{invoicePaymentSettlement.currency || ''}} {{invoicePaymentSettlement.payableOutstandingAmount ? `${invoicePaymentSettlement.payableOutstandingAmount.toLocaleString('en', {minimumFractionDigits:2})}` : '0.00'}}</h2>
                            <h2 v-else class="bold">0.00</h2>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="footer-section">
              <el-button round outline @click="handleInvoicePaymentDialogClose">Close</el-button>
              <el-button class="primary-button" @click="handleSavePayment">Confirm</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { formatDateTime } from '@/utils/dateTime.js'
import { getFileBlobUrl } from '@/services/modules/comm.js'
import { openBlobUrlInNewTab } from '@/utils'

export default {
  name: 'InvoicePayment',
  props: {
    dialogInvoicePayment: Boolean,
    isSellerView: Boolean,
    invoice: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      paymentForm: {
        tableData: [],
        rules: {
          paymentDate: [{ required: true, message: 'Please enter the payment date', trigger: 'blur' }],
          receiptDate: [{ required: true, message: 'Please enter the receipt date', trigger: 'blur' }],
          amountPaid: [
            { type: 'number', required: true, message: 'Please enter the paid amount', trigger: 'blur' },
            { type: 'number', min: 0.000001, message: 'Paid amount must be greater than 0', trigger: 'blur' }
          ],
          amountReceived: [
            { type: 'number', required: true, message: 'Please enter the received amount', trigger: 'blur' },
            { type: 'number', min: 0.000001, message: 'Received amount must be greater than 0', trigger: 'blur' }
          ],
          bankAccount: [{ required: true, message: 'Please enter the bank account', trigger: 'blur' }]
        }
      },
      creditNoteTableData: [],
      debitNoteTableData: [],
      invoicePaymentSettlement: null,
      dialogTitle: null,
      addButtonText: null
    }
  },
  methods: {
    formatDateTime,
    getFileBlobUrl,
    openBlobUrlInNewTab,
    previewFile (fileId) {
      this.getFileBlobUrl(fileId).then(url => {
        this.openBlobUrlInNewTab(url)
      }).catch(e => {

      })
    },
    handleAddPayment () {
      let newPayment = {}
      if (this.isSellerView) {
        newPayment = {
          receiptDate: null,
          amountReceived: null,
          bankAccount: null,
          transactionId: null,
          remark: null
        }
      } else {
        newPayment = {
          receiptDate: null,
          amountReceived: null,
          bankAccount: null,
          transactionId: null,
          remark: null
        }
      }
      if (this.paymentForm.tableData) {
        this.paymentForm.tableData.push(newPayment)
      } else {
        this.paymentForm.tableData = [newPayment]
      }
    },
    handleRemovePayment (index) {
      this.paymentForm.tableData.splice(index, 1)
    },
    handleSavePayment () {
      const sendRequest = async () => {
        const data = {
          invoiceId: this.invoice.id,
          invoicePayments: [...this.paymentForm.tableData],
          invoiceReceipts: [...this.paymentForm.tableData]
        }
        let url
        if (this.isSellerView) {
          delete data.invoicePayments
          url = `${this.$apis.receipts}/batchSaveDelete`
        } else {
          delete data.invoiceReceipts
          url = `${this.$apis.payments}/batchSaveDelete`
        }
        try {
          const res = await this.$request.post({
            url,
            data
          })
          if (res?.code === 1000) {
            this.handleInvoicePaymentDialogClose()
          }
        } catch (e) {

        }
      }

      this.$refs.paymentForm.validate(valid => {
        if (valid) {
          sendRequest()
        }
      })
    },
    async getInvoicePaymentSettlementById (id) {
      const paymentOrReceipt = this.isSellerView ? 'receiptSettlementSummary' : 'paymentSettlementSummary'
      try {
        const res = await this.$request.get({ url: `${this.$apis.invoices}/${id}/${paymentOrReceipt}` })
        if (res?.code === 1000) {
          return res.data
        }
      } catch (e) {}
    },
    handleInvoicePaymentDialogOpen () {
      this.dialogTitle = this.isSellerView ? 'Receipt Information' : 'Payment Information'
      this.addButtonText = this.isSellerView ? 'Add Receipt' : 'Add Payment'
      this.paymentForm.tableData = this.isSellerView ? this.invoice.invoiceReceipts : this.invoice.invoicePayments
      this.getInvoicePaymentSettlementById(this.invoice.id).then(data => {
        this.invoicePaymentSettlement = data
      })
      this.debitNoteTableData = this.invoice.appliedNotes.filter(n => n.noteType === 'DEBIT_NOTE')
      this.creditNoteTableData = this.invoice.appliedNotes.filter(n => n.noteType === 'CREDIT_NOTE')
    },
    handleInvoicePaymentDialogClose () {
      this.$emit('handleInvoicePaymentDialogClose')
    }
  }
}

</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
    padding: 30px 0px;
}
h3{
  text-align: left;
}
.header-section, .paymentForm, .note-section, .summary-section, .footer-section{
  padding:0px 20px ;
}
.header-section {
    display: flex;
    justify-content: space-between;
}
.el-table-payment-form {
    /deep/.el-table__header th{
        font-weight:900 ;
        font-size: 14px;
        &>div span {
            color: #D35333;
        }
    }
     /deep/.el-textarea__inner {
      height: 40px;
      resize: none;
      word-break: break-all;
    }
}

.summary-section {
    .summary-section-left> div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        hr {
            align-self: center;
            border-top:1px dashed rgb(3, 8, 59);
            width:75%;
        }
    }
    .summary-section-right {
      height: '100%';
      display: flex;
      justify-content: space-evenly;
      margin:auto 0px ;
      p,h2{
        text-align: left;
      }
    }
    .bold {
        font-weight: bold;
    }
}

.footer-section {
    justify-content: right;
    padding: 10px 20px 0 0;
}

.el-icon-time + span {
    margin-left: 5px;
}
</style>
